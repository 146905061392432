import { createStore } from 'redux-dynamic-modules-core';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createReduxEnhancer } from '@sentry/react';

const store = createStore({
  initialState: {},
  extensions: [getSagaExtension()],
  enhancers: [createReduxEnhancer()],
  advancedComposeEnhancers: composeWithDevTools({
    maxAge: 500
  })
});

export { store as default, store };
