import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

interface ConfigureSentryProps {
  children: React.ReactNode;
  sentryDSN?: string;
  environmentName?: string;
}

export const ConfigureSentry: React.FC<ConfigureSentryProps> = ({ children, sentryDSN, environmentName }) => {
  useEffect(() => {
    Sentry.init({
      dsn: sentryDSN || '',
      environment: environmentName || window.location.host,
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          console: false,
          fetch: true,
          xhr: true,
        }),
        new Sentry.BrowserTracing({
          // Routing instrumentation code
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
      beforeSend(event) {
        if (
          window.location.host.includes('local') ||
          window.location.host.includes('.dev.') ||
          window.location.host.includes('.127.') ||
          window.location.host.includes('ngrok')
        ) {
          return null;
        }
        // Filter sensitive data from the event before sending it
        if (event.extra) {
          event.extra.user = {
            token: 'FILTERED',
          };
        }
        return event;
      },
      tracesSampleRate: 1.0,
    });
  }, []);

  return <>{children}</>;
};
