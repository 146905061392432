import { j as selectAvailablePackagesTableStatus, k as selectAvailablePackagesTablePackages, l as selectAvailablePackagesTableHasMore, c as actions } from './index.esm2.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import '@mui/material/Grid';
import '@mui/material/Divider';
import '@mui/material/Typography';
import 'react-intl';
import { P as PackagesSearchBanner, a as PackagesVirtualTable } from './PackagesSearchBanner.esm.js';
import 'redux-saga/effects';
import '@reduxjs/toolkit';
import '@aiware/shared/redux';
import '@aiware/os/helpers';
import 'lodash/isEqual';
import '@aiware/shared/reusable-utils';
import '@aiware/js/panel';
import '@mui/material/Box';
import 'lodash';
import '@mui/material/InputAdornment';
import '@mui/icons-material/Search';
import '@mui/material/Button';
import '@aiware/shared/dynamic-modules';
import 'tss-react/mui';
import 'lodash/debounce';
import 'redux';
import '@aiware/os/resource-center/shared';
import '@aiware/shared/icons';
import '@aiware/ui';
import '@aiware/shared/intl';
import '@aiware/shared/theme';
import 'classnames';
import '@aiware/shared/assets';
import '@aiware/shared/permissions';
import '@aiware/js/interfaces';
import '@mui/material/IconButton';
import '@mui/material/Tooltip';
import '@mui/icons-material';
import '@mui/material/Dialog';
import '@mui/material/DialogActions';
import '@mui/material/DialogContent';
import '@mui/material/DialogContentText';
import '@mui/material/DialogTitle';
import '@mui/system';

const AvailablePackagesTable = ({
  type,
  onSearch,
  onEditPackage,
  onOpenPackageDetails
}) => {
  const dispatch = useDispatch();
  const uiStatus = useSelector(selectAvailablePackagesTableStatus(`${type}Package`));
  const packages = useSelector(selectAvailablePackagesTablePackages(`${type}Package`));
  const hasMore = useSelector(selectAvailablePackagesTableHasMore(`${type}Package`));
  const handleFetchMore = useCallback(() => {
    dispatch(actions.fetchPackagesStart(`${type}Package`));
  }, [dispatch]);
  return jsxs(Box, Object.assign({
    sx: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  }, {
    children: [jsx(PackagesSearchBanner, {
      onSearch: onSearch
    }), jsx(PackagesVirtualTable, {
      packages: packages,
      hasMore: hasMore,
      isLoading: uiStatus === 'pending',
      isErrored: uiStatus === 'failure',
      fetchMore: handleFetchMore,
      onEditPackage: onEditPackage,
      onOpenPackageDetails: onOpenPackageDetails,
      tabType: type
    })]
  }));
};

export { AvailablePackagesTable, AvailablePackagesTable as default };
