import { makeStyles } from 'tss-react/mui';

makeStyles()(theme => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    height: '55px',
    padding: theme.spacing(6),
    borderBottom: '1px solid rgb(219, 226, 234)'
  },
  headerActionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'flex-end',
    paddingRight: '10px'
  },
  title: {
    color: '#2A323C'
  }
}));
makeStyles()(_theme => ({
  container: {
    padding: '20px'
  },
  step: {
    '& svg': {
      fontSize: '37px',
      fill: '#465364'
    },
    '& text': {
      fontSize: '10px'
    }
  },
  previousStep: {
    '&+.Sdk-MuiStepConnector-horizontal': {
      display: 'none'
    },
    '& svg': {
      border: '2px solid white',
      borderRadius: '50%',
      fill: '#388E3C'
    }
  },
  futureStep: {
    '& svg': {
      fill: 'transparent!important',
      border: '1px solid',
      borderRadius: '50%'
    },
    '& text': {
      fill: '#5C6269'
    }
  }
}));
makeStyles()(() => ({
  panelContainer: {
    height: `calc(100% - 1px)`
  },
  tabContainer: {
    height: 'calc(100% - 77px - 61px)'
  }
}));
const useBasicInfoStyles = makeStyles()(theme => ({
  container: {
    paddingTop: '30px',
    paddingBottom: '30px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700,
    color: '#2A323C',
    lineHeight: '26px'
  },
  description: {
    lineHeight: '26px'
  },
  input: {
    width: '100%',
    marginTop: '20px',
    '&::placeholder': {
      color: '#9CA8B4'
    }
  },
  placeholder: {
    color: '#9CA8B4',
    background: 'white',
    '&[data-shrink="true"]': {
      padding: '0 7px'
    }
  },
  logoTitle: {
    color: '#2A323C',
    lineHeight: '26px',
    marginTop: '20px'
  },
  logoContainer: {
    marginTop: '20px'
  },
  cancelButton: {
    marginRight: '20px',
    color: '#2A323C'
  }
}));
const usePackageStyles = makeStyles()(theme => ({
  stepTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1)
  },
  container: {
    paddingTop: '30px',
    paddingBottom: '30px',
    height: 'calc(100% - 280px)'
  },
  controlsContainer: {
    marginTop: '15px'
  },
  paddedContainer: {
    padding: theme.spacing(6, 6, 0, 6)
  },
  showRecords: {
    marginTop: theme.spacing(3)
  },
  dropdownLabel: {
    fontSize: '14px'
  },
  selectResourceType: {
    '& > div': {
      padding: theme.spacing(1, 0, 1, 3)
    },
    height: '36px',
    width: '200px'
  },
  resourceFilter: {
    '& input': {
      height: '36px',
      width: '380px',
      padding: '0px'
    },
    '& div': {
      borderRadius: '18px'
    }
  }
}));
const usePackageRowStyles = makeStyles()(() => ({
  gridContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  packageLogoContainer: {
    width: '24px',
    height: '24px',
    borderRadius: '12px'
  },
  packageName: {
    color: '#2A323C',
    fontWeight: 600,
    fontSize: '14px'
  },
  tableDivider: {
    marginTop: '0px'
  },
  addedIcon: {
    fill: '#0C53B0',
    cursor: 'pointer'
  },
  addIcon: {
    fill: '#555F7C',
    cursor: 'pointer'
  },
  version: {
    fontSize: '14px'
  }
}));
const usePackageButtonsStyles = makeStyles()(theme => ({
  actionButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: '30px',
    paddingBottom: '30px',
    '& button:first-of-type': {
      color: '#2A323C',
      marginRight: '20px'
    }
  }
}));

export { usePackageStyles as a, usePackageRowStyles as b, usePackageButtonsStyles as c, useBasicInfoStyles as u };
