import { createAction } from '@reduxjs/toolkit';

const setData = createAction('Set Data');

const logout = createAction('os-app-bar-panel/logout');
const logoutUser = createAction('os-app-bar-panel/users/logout');
const listenLogout = createAction('logoutListener');
const updateActivityStatus = createAction('os-app-bar-panel/users/updateActivityStatus');

const appSwitcherSetupListener = createAction(`aiware/set up app switch listener`);
const appSwitcherHandleClick = createAction(`aiware/handle app switch`);
const appSwitcherHandleClickAppNatively = createAction(`aiware/applications/get handle click app`);

const DESKTOP_APP_ID = 'e4739d44-53d2-4153-b55f-5e246fc989b1';

export { DESKTOP_APP_ID, appSwitcherHandleClick, appSwitcherHandleClickAppNatively, appSwitcherSetupListener, listenLogout, logout, logoutUser, setData, updateActivityStatus };
