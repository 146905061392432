import { jsx, jsxs } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { Snackbar, Alert, Stack, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarSelector, clearMessage } from '@aiware/shared/redux';
export { getSnackbarModule } from '@aiware/shared/redux';
import { makeStyles } from 'tss-react/mui';
import { actions } from '@aiware/os/resource-center/packages';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((_theme, {
  maxWidth: _maxWidth = 'none'
}) => ({
  sdkSnackbarContainer: {
    '& svg': {
      fill: 'white'
    },
    maxWidth: _maxWidth
  }
}));
function CustomSnackbar() {
  const dispatch = useDispatch();
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const {
    message,
    duration,
    isLoaderVisible
  } = useSelector(snackbarSelector);
  const {
    classes
  } = useStyles({
    maxWidth: (messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.maxWidth) ? messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.maxWidth : 'none'
  });
  useEffect(() => {
    if (message === null || message === void 0 ? void 0 : message.content) {
      setSnackPack(prev => [...prev, message]);
    }
  }, [message]);
  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo(Object.assign({}, snackPack[0]));
      setSnackPack(prev => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      dispatch(clearMessage());
      setOpen(false);
    }
  }, [snackPack, messageInfo, open, dispatch]);
  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    dispatch(clearMessage());
  };
  const handleExited = () => {
    setMessageInfo(undefined);
    dispatch(clearMessage());
  };
  const handleBtnClick = reduxActionType => {
    if (typeof reduxActionType === 'string') {
      dispatch({
        type: reduxActionType
      });
      if (reduxActionType === 'dismiss') {
        setOpen(false);
        dispatch(clearMessage());
        return;
      } else {
        dispatch(actions.openResourcesDetailsPanel());
      }
    } else {
      dispatch(reduxActionType);
    }
    setOpen(false);
    dispatch(clearMessage());
  };
  const severity = isLoaderVisible ? 'info' : (messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.severity) || 'info';
  const autoHideDuration = isLoaderVisible || (messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.removeIcon) ? null : duration || 3000;
  const renderHTMLContent = content => jsx("div", {
    dangerouslySetInnerHTML: {
      __html: content
    }
  });
  return jsx(Snackbar, Object.assign({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right'
    },
    open: open,
    autoHideDuration: autoHideDuration,
    onClose: handleClose,
    TransitionProps: {
      onExited: handleExited
    },
    className: classes.sdkSnackbarContainer,
    "data-snackbarid": (messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.key) || 'na',
    "data-testid": `snackbar.message.${(messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.severity) || 'na'}`
  }, {
    children: jsx(Alert, Object.assign({
      onClose: (messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.removeIcon) ? undefined : handleClose,
      icon: (messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.removeIcon) && false,
      severity: severity,
      elevation: 6,
      variant: "filled"
    }, {
      children: jsxs(Stack, Object.assign({
        direction: "row",
        sx: {
          display: 'flex',
          alignItems: 'center'
        }
      }, {
        children: [isLoaderVisible && !(messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.removeIcon) && jsx(CircularProgress, {
          size: "20px",
          sx: {
            color: 'white',
            marginRight: '15px'
          }
        }), renderHTMLContent((messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.content) || ''), (messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.actions) && jsx(Box, Object.assign({
          sx: {
            display: 'flex',
            justifyContent: 'flex-end'
          }
        }, {
          children: messageInfo.actions.map((action, index) => jsx(Button, Object.assign({
            onClick: () => handleBtnClick(action.reduxActionType),
            variant: "outlined",
            "data-testid": `snack-bar-btn-${index}`,
            sx: {
              fontSize: '12px',
              whiteSpace: 'nowrap',
              color: '#fff',
              fontWeight: 600,
              marginLeft: '10px',
              border: ' 1px solid #fff',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#fff',
                border: ' 1px solid #fff',
                color: '#3c52b2'
              }
            }
          }, {
            children: action.actionButtonText
          }), index))
        }))]
      }))
    }))
  }), messageInfo ? messageInfo.key : undefined);
}

export { CustomSnackbar as default };
