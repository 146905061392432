import { e as selectIsEditMode, h as selectActiveStep, p as panelId, g as actions, S as SHARED_TEXT } from './index.esm2.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import { Stack, Button, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';
import 'redux-saga/effects';
import '@aiware/os/helpers';
import '@aiware/shared/redux';
import '@aiware/os/resource-center/shared';
import 'lodash/isEqual';

var ENextButtonType;
(function (ENextButtonType) {
  ENextButtonType["SKIP"] = "SKIP";
  ENextButtonType["NEXT"] = "NEXT";
  ENextButtonType["BACK"] = "BACK";
  ENextButtonType["CANCEL"] = "CANCEL";
  ENextButtonType["CREATE_PACKAGE"] = "CREATE_PACKAGE";
  ENextButtonType["UPDATE_PACKAGE"] = "UPDATE_PACKAGE";
})(ENextButtonType || (ENextButtonType = {}));
const PanelControls = ({
  disableNext,
  disableBack,
  onClickNext,
  onClickBack,
  nextButtonType: _nextButtonType = ENextButtonType.NEXT,
  invalidFields: _invalidFields = false
}) => {
  const dispatch = useDispatch();
  const isEditMode = useSelector(selectIsEditMode);
  const activeStep = useSelector(selectActiveStep);
  const handleClickBack = () => {
    if (onClickBack) {
      onClickBack();
    }
    if (activeStep === 1) {
      dispatch(hidePanel(panelId));
      return;
    }
    dispatch(actions.stepper.backStep());
  };
  const handleClickNext = () => {
    dispatch(actions.stepper.nextStep());
    if (onClickNext) {
      onClickNext();
    }
  };
  const getBackLabel = () => {
    if (isEditMode) {
      return SHARED_TEXT.panel.cancel();
    }
    if (activeStep === 1) return SHARED_TEXT.panel.cancel();
    return SHARED_TEXT.panel.back();
  };
  const getNextLabel = () => {
    switch (_nextButtonType) {
      case ENextButtonType.NEXT:
        {
          return SHARED_TEXT.panel.next();
        }
      case ENextButtonType.SKIP:
        {
          return SHARED_TEXT.panel.skip();
        }
      case ENextButtonType.CREATE_PACKAGE:
        {
          return SHARED_TEXT.panel.createPackage();
        }
      case ENextButtonType.UPDATE_PACKAGE:
        {
          return SHARED_TEXT.panel.updatePackage();
        }
      default:
        return null;
    }
  };
  const getNextTooltip = () => {
    if (disableNext && _invalidFields) {
      return SHARED_TEXT.panel.someFieldsInvalidMessage();
    }
    if (disableNext) {
      return SHARED_TEXT.panel.incompleteFieldsMessage();
    }
    return '';
  };
  return jsxs(Stack, Object.assign({
    sx: {
      position: 'absolute',
      bottom: '30px',
      right: '30px',
      display: 'flex',
      gap: '15px'
    },
    direction: "row"
  }, {
    children: [jsx(Button, Object.assign({
      "data-testid": 'rc-package-manager-back-btn',
      sx: {
        color: '#2A323C'
      },
      onClick: handleClickBack,
      disabled: disableBack
    }, {
      children: getBackLabel()
    })), jsx(Tooltip, Object.assign({
      placement: 'top',
      title: getNextTooltip()
    }, {
      children: jsx("span", {
        children: jsx(Button, Object.assign({
          "data-testid": 'rc-package-manager-next-btn',
          onClick: handleClickNext,
          variant: "contained",
          disabled: disableNext
        }, {
          children: getNextLabel()
        }))
      })
    }))]
  }));
};

export { ENextButtonType as E, PanelControls as P };
